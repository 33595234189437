import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Img from 'gatsby-image'
import Helmet from 'react-helmet'
import Content, { HTMLContent } from '../helper/Content'
import { LangContext } from '../context/langContext'
import useSiteMetadata from '../helper/SiteMetadata'

export const ProductPageTemplate = ({
  title,
  content,
  contentComponent,
  image,
}) => {
  const PageContent = contentComponent || Content
  const { state2 } = useContext(LangContext)
  const isInvert = state2.isNight
  const inverClass = isInvert ? 'section' : 'section section-invert'
  return (
    <div className="content">
      <div className="full-width-image margin-top-0 ">
        <Img
          className="mainimage"
          loading={'lazy'}
          fluid={!!image.childImageSharp ? image.childImageSharp.fluid : image}
          alt="Dekko"
        />
        <h2
          className="has-text-weight-bold is-size-3  tracking-in-expand"
          style={{
            boxShadow:
              '0.5rem 0 0 rgba(255, 255, 255, .2), -0.5rem 0 0 rgba(255, 255, 255, .2)',
            backgroundColor: 'rgba(255, 255, 255, .1)',
            color: '#393939',
            padding: '1rem',
          }}
        >
          {title}
        </h2>
      </div>
      <section className={`${inverClass} section--gradient`}>
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className={`${inverClass} `}>
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  {title}
                </h2>
                <PageContent className="content" content={content} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

ProductPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const ProductPage = ({ data }) => {
  const { frontmatter, fields, html } = data.markdownRemark
  const { title, image, subheading, description, og_image } = frontmatter
  const { siteUrl } = useSiteMetadata()
  const structuredData = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: 'Dekko',
    image: `${image.publicURL}`,
    '@id': '',
    url: 'https://awesome-agnesi-30e8ff.netlify.app',
    telephone: `06 30 8682021`,
  })

  return (
    <Layout>
      <Helmet>
        <title>{'Dekko|' + frontmatter.title}</title>
        <link rel="canonical" href={`https://dekko.hu${fields.slug}`} />
        <meta name="description" content={description} />
        <meta name="theme-color" content="#1D1D1D" />
        <meta name="keywords" content="keyword 1, keyword 2, keyword 3" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:site_name" content={title} />
        <meta property="og:url" content={siteUrl} />
        <meta
          property="og:image"
          content={siteUrl + image.childImageSharp.og_image.src}
        />
        <meta property="og:image:alt" content="Dekko" />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content="hu_HU" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta
          name="twitter:image"
          content={siteUrl + image.childImageSharp.og_image.src}
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <ProductPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        image={frontmatter.image}
        content={html}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: structuredData }}
      ></script>
    </Layout>
  )
}

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      field: PropTypes.object,
      html: PropTypes.object,
    }),
  }),
}

export default ProductPage

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                src
                srcSet
            }
            og_image: fluid(maxWidth: 500, maxHeight: 500, quality: 100, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
                src
                srcSet
            }
          }
        }
      }
    }
  }
`
